<!--<template>-->
<!--  <div>-->
<!--    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">-->
<!--      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>管道管理</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>数据导出</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>{{currentCoal}} 系统列表</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
<!--&lt;!&ndash;    <span style="font-size: 12px;">{{this.currentCoal}}概述：</span>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="coalblock" v-for="item in options" :key="item.title">&ndash;&gt;-->
<!--&lt;!&ndash;      <span style="font-size: 12px; color:#9196a1 ;">{{item.title}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;      <br />&ndash;&gt;-->
<!--&lt;!&ndash;      <span style="font-size: 24px;">{{item.num}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->

<!--    &lt;!&ndash;			<span v-if="setting.advancesearch" style="font-size: 10px;">搜索：</span>&ndash;&gt;-->
<!--    &lt;!&ndash;			<el-select v-if="setting.advancesearch" v-model="currentCoalId" placeholder="请选择项目" @change="selectCoal()">&ndash;&gt;-->
<!--    &lt;!&ndash;				<el-option v-for="item in coaloptions" :key="item.id" :label="item.coal" :value="item.id">&ndash;&gt;-->
<!--    &lt;!&ndash;				</el-option>&ndash;&gt;-->
<!--    &lt;!&ndash;			</el-select>&ndash;&gt;-->

<!--    <el-form :inline="true">-->
<!--      <el-form-item>-->
<!--        <span v-if="setting.advancesearch" style="font-size: 10px;">搜索：</span>-->
<!--        <el-select v-if="setting.advancesearch"  v-model="currentCoalId" placeholder="请选择" @change="handleChange()" style="width: 400px" clearable>-->
<!--          <el-option v-for="item in coaloptions" :key="item.id" :label="item.coal" :value="item.id">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      <el-form-item>-->
<!--&lt;!&ndash;        <el-button v-if="setting.batchRemove" type="primary" @click="handleExportSystem()" :disabled="dataExportSelections.length <= 0" >批量导出</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;        <el-button type="danger">全部导出</el-button>&ndash;&gt;-->
<!--      </el-form-item>-->
<!--    </el-form>-->

<!--    <el-table-->
<!--        :data="tableData"-->
<!--        style="min-width: 100%"-->
<!--        border-->
<!--        v-loading="dataListLoading"-->
<!--        @selection-change="selectionChangeHandle"-->
<!--    >-->
<!--      <el-table-column-->
<!--          type="selection"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="5%">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          label="序号"-->
<!--          type="index"-->
<!--          min-width="5%"-->
<!--          align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{(page - 1) * size + scope.$index + 1}}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="id"-->
<!--          label="系统id"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="5%"-->
<!--          v-if=false-->
<!--      >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="name"-->
<!--          label="系统名称"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="5%" >-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="subSystemCount"-->
<!--          label="子系统个数"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="10%">-->
<!--      </el-table-column>-->
<!--&lt;!&ndash;      <el-table-column&ndash;&gt;-->
<!--&lt;!&ndash;          prop="datasize"&ndash;&gt;-->
<!--&lt;!&ndash;          label="数据量"&ndash;&gt;-->
<!--&lt;!&ndash;          header-align="center"&ndash;&gt;-->
<!--&lt;!&ndash;          align="center"&ndash;&gt;-->
<!--&lt;!&ndash;          min-width="10%">&ndash;&gt;-->
<!--&lt;!&ndash;      </el-table-column>&ndash;&gt;-->
<!--      <el-table-column-->
<!--          prop="createdate"-->
<!--          label="创建时间"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="10%">-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          label="操作"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="10%">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button-->
<!--              type="text"-->
<!--              size="small"-->
<!--              @click="handleCheck(scope.row)"-->
<!--              :disabled="scope.row.subSystemCount == 0?true:false"-->
<!--          >查看</el-button>  &lt;!&ndash; 这里要改，当子系统个数为0时，查看按钮不可用 &ndash;&gt;-->
<!--&lt;!&ndash;          <el-button&ndash;&gt;-->
<!--&lt;!&ndash;              type="text"&ndash;&gt;-->
<!--&lt;!&ndash;              size="small"&ndash;&gt;-->
<!--&lt;!&ndash;              @click="handleExportSystem(scope.row)"&ndash;&gt;-->
<!--&lt;!&ndash;          >导出</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;          <el-button&ndash;&gt;-->
<!--&lt;!&ndash;              type="text"&ndash;&gt;-->
<!--&lt;!&ndash;              size="small"&ndash;&gt;-->
<!--&lt;!&ndash;              @click="handleDownload(scope.row)"&ndash;&gt;-->
<!--&lt;!&ndash;          >下载</el-button>&ndash;&gt;-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column-->
<!--          prop="remark"-->
<!--          label="备注"-->
<!--          header-align="center"-->
<!--          align="center"-->
<!--          min-width="20%">-->
<!--      </el-table-column>-->

<!--    </el-table>-->

<!--    &lt;!&ndash; 分页区域 &ndash;&gt;-->
<!--    <div style="display: flex;justify-content: flex-end">-->
<!--      <el-pagination-->
<!--          background-->
<!--          @current-change="currentChange"-->
<!--          @size-change="sizeChange"-->
<!--          :current-page="page"-->
<!--          :page-size="size"-->
<!--          layout="sizes, prev, pager, next, jumper, ->, total, slot"-->
<!--          :total="total">-->
<!--      </el-pagination>-->
<!--    </div>-->
<!--  </div>-->
<!--  &lt;!&ndash;			<el-card>&ndash;&gt;-->
<!--  &lt;!&ndash;				<el-table :data="tableData" style="width: 100%" border stripe>&ndash;&gt;-->
<!--  &lt;!&ndash;					<el-table-column v-if="setting.batchRemove" type="selection" header-align="center" align="center"&ndash;&gt;-->
<!--  &lt;!&ndash;						width="50">&ndash;&gt;-->
<!--  &lt;!&ndash;					</el-table-column>&ndash;&gt;-->
<!--  &lt;!&ndash;					<el-table-column prop="name" label="系统" header-align="center" align="center" width="180">&ndash;&gt;-->
<!--  &lt;!&ndash;					</el-table-column>&ndash;&gt;-->
<!--  &lt;!&ndash;					<el-table-column prop="subSystemCount" label="子系统个数" header-align="center" align="center"&ndash;&gt;-->
<!--  &lt;!&ndash;						width="180">&ndash;&gt;-->
<!--  &lt;!&ndash;					</el-table-column>&ndash;&gt;-->
<!--  &lt;!&ndash;					<el-table-column prop="datasize" label="数据量" header-align="center" align="center" width="180">&ndash;&gt;-->
<!--  &lt;!&ndash;					</el-table-column>&ndash;&gt;-->
<!--  &lt;!&ndash;					&lt;!&ndash; <el-table-column prop="subdevice_num" label="附属设备个数" header-align="center" align="center" width="180">&ndash;&gt;-->
<!--  &lt;!&ndash;                    </el-table-column> &ndash;&gt;&ndash;&gt;-->
<!--  &lt;!&ndash;					<el-table-column label="操作" header-align="center" align="center" width="180">&ndash;&gt;-->
<!--  &lt;!&ndash;						<template slot-scope="scope">&ndash;&gt;-->
<!--  &lt;!&ndash;							<el-button type="text" size="small">导出</el-button>&ndash;&gt;-->
<!--  &lt;!&ndash;						</template>&ndash;&gt;-->
<!--  &lt;!&ndash;					</el-table-column>&ndash;&gt;-->
<!--  &lt;!&ndash;				</el-table>&ndash;&gt;-->
<!--  &lt;!&ndash;				&lt;!&ndash; 分页区域 &ndash;&gt;&ndash;&gt;-->
<!--  &lt;!&ndash;				<div style="display: flex;justify-content: flex-end">&ndash;&gt;-->
<!--  &lt;!&ndash;					<el-pagination background @current-change="currentChange" @size-change="sizeChange"&ndash;&gt;-->
<!--  &lt;!&ndash;						:current-page="page" :page-size="size"&ndash;&gt;-->
<!--  &lt;!&ndash;						layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">&ndash;&gt;-->
<!--  &lt;!&ndash;					</el-pagination>&ndash;&gt;-->
<!--  &lt;!&ndash;				</div>&ndash;&gt;-->
<!--  &lt;!&ndash;			</el-card>&ndash;&gt;-->
<!--</template>-->

<!--<script>-->

<!--export default {-->
<!--  name: "dataExport",-->
<!--  data() {-->
<!--    return {-->
<!--      dataListLoading: false,-->
<!--      currentCoal: "",-->
<!--      coaloptions: [],-->
<!--      currentCoalId: '',-->
<!--      options: [{-->
<!--        title: '子系统个数',-->
<!--        num: '14个'-->
<!--      }, {-->
<!--        title: '子系统数据量',-->
<!--        num: '25TB'-->
<!--      }, {-->
<!--        title: 'hbase表',-->
<!--        num: '186个'-->
<!--      }, {-->
<!--        title: 'hive表',-->
<!--        num: '157'-->
<!--      }, {-->
<!--        title: 'influxdb表',-->
<!--        num: '206'-->
<!--      }],-->
<!--      tableData: [],-->
<!--      page: 1, //当前第几页-->
<!--      size: 10, //当前每页页数-->
<!--      total: 0, //全部数据量-->
<!--      name: '数据导出',-->
<!--      setting: {-->
<!--        batchRemove: true,-->
<!--        advancesearch: true,-->

<!--      },-->

<!--      dataExportSelections: []-->
<!--    }-->
<!--  },-->
<!--  mounted(){-->
<!--    //获取session中的user的id和name-->
<!--    this.currOperator = JSON.parse(-->
<!--        window.sessionStorage.getItem("user")-->
<!--    ).userId;-->
<!--    this.currOperatorName = JSON.parse(-->
<!--        window.sessionStorage.getItem("user")-->
<!--    ).username;-->
<!--    this.initData();-->
<!--    //根据名字查询系统设置中的结果-->
<!--    this.getRequest('/systemSetting/getSettingInfoByName/?name='+this.name).then(resp=>{-->
<!--      if (resp.data){-->
<!--        this.setting.batchRemove = resp.data.batchremove;-->
<!--        this.setting.advancesearch = resp.data.advancesearch;-->
<!--        this.size = resp.data.numberperpage;-->

<!--      }-->
<!--    });-->
<!--  },-->
<!--  methods: {-->
<!--    test(){-->
<!--      for (let i in this.coaloptions) {-->
<!--        if (this.coaloptions[i].id == this.currentCoalId){-->
<!--          this.currentCoal = this.coaloptions[i].coal;-->
<!--          break;-->
<!--        }-->
<!--      }-->
<!--    },-->
<!--    selectCoal(){-->
<!--      for (let i in this.coaloptions) {-->
<!--        if (this.coaloptions[i].id == this.currentCoalId){-->
<!--          this.currentCoal = this.coaloptions[i].coal;-->
<!--          break;-->
<!--        }-->
<!--      }-->
<!--      this.initData();-->
<!--    },-->
<!--    getCoalOption(){-->
<!--      this.getRequest('/dataList/getAllCoal/').then(resp=>{-->
<!--        if (resp.data){-->
<!--          this.coaloptions = resp.data;-->
<!--        }-->
<!--      });-->
<!--    },-->
<!--    initData(){-->
<!--      this.dataListLoading = true;-->
<!--      this.getRequest("/dataList/getAllCoal/").then((resp) => {-->
<!--        if (resp.data) {-->
<!--          this.coaloptions = resp.data;-->
<!--          if (this.coaloptions.length != 0) {-->
<!--            this.currentCoal = this.coaloptions[0].coal-->
<!--            this.currentCoalId = this.coaloptions[0].id-->
<!--            console.log(this.coaloptions)-->
<!--            console.log(this.coaloptions)-->
<!--            this.getRequest(-->
<!--                "/coalSystemManage/getSystemInfoByCoalId/?coalId=" + this.currentCoalId + "&page=" +-->
<!--                this.page +-->
<!--                "&size=" +-->
<!--                this.size-->
<!--            ).then((resp) => {-->
<!--              if (resp) {-->
<!--                this.tableData = resp.data.data;-->
<!--                this.total = resp.data.total;-->
<!--                this.overViewFlag = true;-->
<!--              }-->
<!--            });-->
<!--          }-->
<!--          this.dataListLoading = false-->
<!--        }-->
<!--      });-->
<!--      // this.getRequest(-->
<!--      //     "/coalSystemManage/getCoalSystemInfoByPage/?page=" +-->
<!--      //     this.page +-->
<!--      //     "&size=" +-->
<!--      //     this.size + "&userId=" + this.currOperator-->
<!--      // ).then((resp) => {-->
<!--      //   if (resp) {-->
<!--      //     this.tableData = resp.data.data;-->
<!--      //     this.total = resp.data.total;-->
<!--      //     this.overViewFlag = true;-->
<!--      //   }-->
<!--    },-->
<!--    handleChange() {-->
<!--      this.dataListLoading = true-->
<!--      if (this.currentCoalId != '') {-->
<!--        this.getRequest(-->
<!--            "/coalSystemManage/getSystemInfoByCoalId/?coalId=" +-->
<!--            this.currentCoalId +-->
<!--            "&page=" +-->
<!--            this.page +-->
<!--            "&size=" +-->
<!--            this.size-->
<!--        ).then((resp) => {-->
<!--          if (resp.data) {-->
<!--            this.tableData = resp.data.data;-->
<!--            this.total = resp.data.total;-->
<!--            this.overViewFlag = true;-->
<!--          }-->
<!--          this.dataListLoading = false-->
<!--        });-->
<!--      } else {-->
<!--        this.getRequest(-->
<!--            "/coalSystemManage/getCoalSystemInfoByPage/?page=" +-->
<!--            this.page +-->
<!--            "&size=" +-->
<!--            this.size + "&userId=" + this.currOperator-->
<!--        ).then((resp) => {-->
<!--          if (resp) {-->
<!--            this.tableData = resp.data.data;-->
<!--            this.total = resp.data.total;-->
<!--            this.overViewFlag = true;-->
<!--          }-->
<!--          this.dataListLoading = false-->
<!--        });-->
<!--      }-->
<!--      this.test()-->
<!--    },-->
<!--    currentChange(currentPage){-->
<!--      this.page=currentPage;-->
<!--      this.handleChange();-->
<!--    },-->
<!--    sizeChange(currentSize) {-->
<!--      this.size = currentSize;-->
<!--      this.handleChange();-->
<!--    },-->
<!--    handleCheck(system) {-->
<!--      console.log(system)-->
<!--      this.$router.push({-->
<!--        name: 'data-export-equipment',-->
<!--        query: {-->
<!--          systemId: system.id,-->
<!--          systemName: system.name,-->
<!--          crumb: this.currentCoal-->
<!--        }-->
<!--      });-->
<!--    },-->
<!--    // 多选-->
<!--    selectionChangeHandle (val) {-->
<!--      this.dataExportSelections = val-->
<!--    },-->
<!--    handleExportSystem(id) {-->
<!--      let systemIds;-->
<!--      systemIds = id ? [id] : this.dataExportSelections.map(item => {-->
<!--        return item.id-->
<!--      })-->
<!--      this.$confirm(`确定对[id=${systemIds.join(',')}]进行[${id ? '导出' : '批量导出'}]操作?`, '提示', {-->
<!--        confirmButtonText: '确定',-->
<!--        cancelButtonText: '取消',-->
<!--        type: 'warning'-->
<!--      }).then(() => {-->
<!--        this.$http({-->
<!--          url: this.$http.adornUrl('/dataList/export'),-->
<!--          method: 'post',-->
<!--          data: this.$http.adornData(systemIds, false)-->
<!--        }).then(({data}) => {-->
<!--          if (data && data.code === 0) {-->
<!--            this.$message({-->
<!--              message: '操作成功',-->
<!--              type: 'success',-->
<!--              duration: 1500,-->
<!--              onClose: () => {-->
<!--                this.initData()-->
<!--              }-->
<!--            })-->
<!--          } else {-->
<!--            this.$message.error(data.msg)-->
<!--          }-->
<!--        })-->
<!--      }).catch(() => {})-->
<!--    },-->
<!--    handleDownload(row) {-->

<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->

<template>
  <div>
    <div class="mod-config">
      <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>数据导出</el-breadcrumb-item>
      </el-breadcrumb>
      <el-form :inline="true" :model="dataForm">
        <!--    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">-->
        <el-form-item>
          <el-input v-model="dataForm.key" placeholder="项目名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <!--        <el-button type="primary" @click="setAttrabute()" :disabled="projectEnabled===1">设置项目属性</el-button>-->
          <!--        <el-button type="danger" @click="resetAttrabute()" :disabled="projectEnabled===0">重置项目属性</el-button>-->
          <el-button @click="selectByNameAndInit()" :disabled="projectEnabled===0">查询</el-button>
          <el-button @click="init()" :disabled="projectEnabled===0">重置</el-button>
          <!--        <el-button type="primary" @click="addOrUpdateHandle()" :disabled="projectEnabled===0">新增</el-button>-->
          <!--        <el-button  type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0 && projectEnabled===0">批量删除</el-button>-->
        </el-form-item>
      </el-form>
      <el-table :data="tableData" border width="80%" @selection-change="selectionChangeHandle">
        <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50">
        </el-table-column>
        <template v-for="(item,index) in tableCols">
          <el-table-column :prop="item.cols"
                           :label="item.name"
                           :key="index">
          </el-table-column>
        </template>
        <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            width="200"
            label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small "@click="checkHandle(scope.row.id)" >查看子层级</el-button>
<!--            <el-button type="text" size="small "@click="checkHandle(scope.row.id,scope.row.hbaseTableName)" >查看子层级</el-button>-->
<!--            <el-button  type="text" size="small "@click="handleExport(scope.row.id,scope.row.hbaseTableName)" >导出</el-button>-->
<!--            <el-button type="text" size="small "@click="handleDownload(scope.row.id,scope.row.hbaseTableName)" >下载</el-button>-->
            <!--          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)" >修改</el-button>-->
            <!--          <el-button type="text" size="small" style="color: red" @click="deleteHandle(scope.row.id)">删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
      <!-- 弹窗, 新增 / 修改 -->
      <project-attribute-add v-if="projectAttributeAddVisible" ref="projectAttributeAdd" @refreshDataList="init"></project-attribute-add>
      <project-add-or-update v-if="addOrUpdateVisible" ref="ProjectAddOrUpdate" @refreshDataList="init"></project-add-or-update>
      <!--    <create-table v-if="createTablevisible" ref="createTable" @refreshDataList="init"></create-table>-->
    </div>
    <!--    <el-tabs v-model="activeName" type="card">-->
    <!--      <el-tab-pane label="表格信息" name="chart">-->
    <!--        <chart v-if="activeName == 'chart'"></chart>-->
    <!--      </el-tab-pane>-->
    <!--      <el-tab-pane label="图表信息" name="graph">-->
    <!--        <graph v-if="activeName == 'graph'"></graph>-->
    <!--      </el-tab-pane>-->
    <!--    </el-tabs>-->
  </div>
</template>

<script>
import ProjectAttributeAdd from "/src/views/modules/levelManage/project-attribute-add";
import ProjectAddOrUpdate from "/src/views/modules/levelManage/project-attribute-add.vue";
export default {
  name: "projectManage",
  components: {ProjectAddOrUpdate, ProjectAttributeAdd},
  data () {
    return {
      hbaseTableNameCurrent: '',
      activeName: 'chart',
      show: false,
      visible: false,
      projectEnabled: false,
      dataForm: {
        key: ''
      },
      dataListSelections: [],
      tableData: [],
      tableCols: [],
      isLeaf: 0,
      tableId: 0,
      itemId: 0,
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      addOrUpdateVisible: false,
      projectAttributeAddVisible: false,
      enterState: 0,   //进入状态，0代表直接进入，2代表搜索进入
    }
  },
  componets:{
    ProjectAttributeAdd,
    ProjectAddOrUpdate,
  },
  methods: {
    handleExport(id,hbaseTableName) {
      //id原本子设备id，这里为rowid，建立websocket连接
      this.handleLog(id)
      let subEquipmentIds;
      subEquipmentIds = id ? [id] : this.dataExportSelections.map(item => {
        return item.id
      })
      console.log(subEquipmentIds)
      this.$confirm(`确定对[id=${subEquipmentIds.join(',')}]进行[${id ? '导出' : '批量导出'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/dataList/exportSubEquipment'),
          method: 'post',
          // data: this.$http.adornData(subEquipmentIds, false)
          data: this.$http.adornData(hbaseTableName, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            // this.$message({
            //   message: '操作成功',
            //   type: 'success',
            //   duration: 1500,
            //   onClose: () => {
            //     this.initData()
            //   }
            // })
          } else {
            this.$message.error(data.msg)
            // this.websocket.close();
          }
        })
      }).catch(() => {})
    },
    handleLog(id) {
      var websocket = null;
      var host = `ws://${this.$store.state.websocket.ip}:${this.$store.state.websocket.port}/hbase/log/dataExport`;
      // if (window.location.protocol == 'http:') {
      //   host = 'ws://' + window.location.host + '/websocket';
      // } else {
      //   host = 'wss://' + window.location.host + '/websocket';
      // }

      //判断当前浏览器是否支持WebSocket
      if ('WebSocket' in window) {
        websocket = new WebSocket(host);
      } else if ('MozWebSocket' in window) {
        websocket = new MozWebSocket(host);
      } else {
        alert("该浏览器不支持WebSocket！");
      }
      console.log(host)
      this.initWebSocket(id, websocket);

      // if ("WebSocket" in window) {
      //   let url = `ws://localhost/websocket`;
      //   console.log(url);
      //   this.websocket = new WebSocket(url);
      //   this.initWebSocket();
      // }
    },
    handleDownload(id, hbaseTableName) {
      this.$confirm(`确定对[id=${id}]进行下载操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/dataList/downloadSubEquipment'),
          method: 'post',
          // data: this.$http.adornData(id, false)
          data: this.$http.adornData(hbaseTableName, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            console.log(data)
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                window.location.href = "http://" + data.downloadUrl
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})

      // let subEquipmentIds;
      // subEquipmentIds = id ? [id] : this.dataExportSelections.map(item => {
      //   return item.id
      // })
      // console.log(subEquipmentIds)
      // this.$confirm(`确定对[id=${subEquipmentIds.join(',')}]进行[${id ? '下载' : '批量下载'}]操作?`, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.$http({
      //     url: this.$http.adornUrl('/dataList/downloadSubEquipment'),
      //     method: 'post',
      //     data: this.$http.adornData(subEquipmentIds, false)
      //   }).then(({data}) => {
      //     if (data && data.code === 0) {
      //       this.$message({
      //         message: '操作成功',
      //         type: 'success',
      //         duration: 1500,
      //         onClose: () => {
      //           this.initData()
      //         }
      //       })
      //     } else {
      //       this.$message.error(data.msg)
      //     }
      //   })
      // }).catch(() => {})
    },

    checkHandle(id,hbaseTableName){
      //id为第一层id
      this.$http({
        url: this.$http.adornUrl('/levelManage/projectattributes/projectToLevel?id='+id),
        method: 'get',
      }).then(({data}) => {
        if (data && data.code === 0) {
          console.log(data)
          if(data.state===0){
            this.$message({
              message: '当前项目没有绑定层级关系',
              type: 'success',
            })
          }
          else {
            // this.hbaseTableNameCurrent = hbaseTableName
            window.sessionStorage.setItem("enterState",0)
            window.sessionStorage.setItem("tableId",data.tableId)//传入的tableid为第二层表的id

            window.sessionStorage.setItem("projectId",id)
            // window.sessionStorage.setItem("hbaseTableName",hbaseTableName)

            this.$router.push({
              // name: 'universal-table'
              name: 'universal-table-meta-dataExport'
            })
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    resetAttrabute(){
      this.$confirm(`确定对对项目属性进行重置？这会导致已有的项目数据被清空`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/levelManage/projectattributes/dropProject'),
          method: 'get',
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.tableCols=[]
                this.init()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    getDataList(){
      this.$http({
        url: this.$http.adornUrl('/levelManage/projectattributes/hasProject'),
        method: 'get',
      }).then(({data}) =>{
        this.projectEnabled=data.count;
        return this.projectEnabled
      }).then(()=>{
        if(this.projectEnabled===1){
          this.$http({
            url: this.$http.adornUrl('/levelManage/projectattributes/getCols'),
            method: 'get',
          }).then(({data})=>{
            this.tableCols=[]
            for(let i=0;i<data.data.length;i++)
            {
              this.tableCols.push({
                // {
                //   "name": "create_time",
                //     "remark": "创建时间",
                //     "type": "date"
                // },
                //中文列名
                name: data.data[i].remark,
                //存在tabledata里的key
                cols: data.data[i].name,
              })
            }
          }).then(()=>{
            this.$http({
              url: this.$http.adornUrl('/levelManage/projectattributes/getProjectInfo?page='+this.pageIndex+'&size='+this.pageSize  ),
              method: 'get',
            }).then(({data})=>{
              this.tableData=data.data;
              this.totalPage=data.count;
              for(let i=0; i<this.tableData.length;i++) {
                let obj = Object.assign({}, this.tableData[i])
                obj.process = this.global.processMap.get(this.tableData[i].id)
                if (obj.process === undefined) {
                  obj.msg = null;
                } else {
                  if (obj.process !== 0 && obj.process !== 100) {
                    obj.msg = "正在导出，请勿刷新页面"
                  } else {
                    obj.msg = null;
                  }
                }
                this.$set(this.tableData, i, obj)
              }
            })
          })
        }
      })
    },
    init(){
      this.enterState=0
      this.getDataList()
    },
    setAttrabute(){
      this.projectAttributeAddVisible=true
      this.$nextTick(() => {
        this.$refs.projectAttributeAdd.init()
      })
    },
    selectByName() {
      this.enterState=2
      window.sessionStorage.setItem("enterState",this.enterState)
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/levelManage/projectattributes/selectByName?name='+this.dataForm.key+'&page='+this.pageIndex+'&limit='+this.pageSize),
        method: 'get',
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.tableData=data.data;
          this.totalPage = data.count
          for(let i=0; i<this.tableData.length;i++) {
            let obj = Object.assign({}, this.tableData[i])
            obj.process = this.global.processMap.get(this.tableData[i].id)
            if (obj.process === undefined) {
              obj.msg = null;
            } else {
              if (obj.process !== 0 && obj.process !== 100) {
                obj.msg = "正在导出，请勿刷新页面"
              } else {
                obj.msg = null;
              }
            }
            this.$set(this.tableData, i, obj)
          }
          this.showpage=true;
        } else {
          this.dataList = []
          this.totalPage = 0
          this.showpage=true;
        }
        this.dataListLoading = false
      })
    },
    selectByNameAndInit()
    {
      this.pageIndex=1
      this.selectByName()
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      if(this.enterState===0||this.enterState==='0')
      {
        this.init()
      }
      else
      {
        this.selectByNameAndInit()
      }
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      if(this.enterState===0||this.enterState==='0')
      {
        this.init()
      }
      else
      {
        this.selectByName()
      }
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.ProjectAddOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle (id) {
      var ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?这也会解除${id ? '它' : '它们'}和层级间的关系`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/levelManage/projectattributes/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
  },
  mounted() {
    // console.log('this.global.processMap.get(\'8\')',this.global.processMap.get('8'))
    this.init();
  }
}
</script>

<style scoped>

</style>